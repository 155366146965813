module.exports = {
	favorites: (e) => {
		return {
			data: null,
			starred: false,
			isLoading: false,
			sku: null,
			lists: null,
			init() {
				this.starred = e
			},
			add(sku) {
				this.isLoading = true;
				this.sku = sku
				const item = { Sku: this.sku };

				fetch(`/umbraco/surface/FavoritesWebSurface/add/`, {
					method: 'POST', // or 'PUT'
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(item),
				})
					.then(response => response.json())
					.then(data => {
						if (data === true) {
							this.data = data;
							this.starred = true;
						}
						else {
							this.lists = data;
						}
						this.isLoading = false;
					})
					.catch((error) => {
						console.error('Error:', error);
						this.isLoading = false;
					});
			},
			addToList(id) {
				if (id) {
					this.isLoading = true;
					const item = { Sku: this.sku, Ids: id };

					fetch(`/umbraco/surface/FavoritesWebSurface/AddToLists/`, {
						method: 'POST', // or 'PUT'
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(item),
					})
						.then(response => response.json())
						.then(data => {
							if (data === true) {
								this.data = data;
								this.starred = true;
								this.lists = null;
							}
							else {
								this.lists = data;
							}
							this.isLoading = false;
						})
						.catch((error) => {
							console.error('Error:', error);
							this.isLoading = false;
						});
				}
			},
			remove(sku) {
				this.isLoading = true;
				const item = { Sku: sku };

				fetch(`/umbraco/surface/FavoritesWebSurface/remove/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(item),
				})
					.then(response => response.json())
					.then(data => {
						this.data = data;
						this.starred = false;
						this.isLoading = false;
					})
					.catch((error) => {
						console.error('Error:', error);
						this.isLoading = false;
					});
			}
		}
	}
}
