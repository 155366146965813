// Polyfills
import objectFitImages from "object-fit-images"
import objectFitVideos from "object-fit-videos"

objectFitImages()
objectFitVideos()

import "picturefill"
import "lazysizes"

import { tns } from "./../node_modules/tiny-slider/src/tiny-slider"
window.tns = tns

var SimpleLightbox = require('simple-lightbox');
window.lightbox = SimpleLightbox
var lightboxes = document.querySelectorAll(".lightbox-wrapper")
if (lightboxes) {
	lightboxes.forEach(lightbox => {
		new SimpleLightbox({
			elements: lightbox.querySelectorAll(".lightbox-element"),
			closeBtnCaption: '',
			nextBtnCaption: '',
			prevBtnCaption: '',
		})
	});
}

import { screens } from './../styles/tailwind/tailwind.screens'
window.screens = {}

Object.keys(screens).map(screen => {
	let mm = window.matchMedia(`(min-width:${screens[screen]})`)

	mm.addListener(event =>
		window.dispatchEvent(new CustomEvent('matchmedia', {
			'detail': {
				'screen': screen,
				'media': event.media,
				'matches': event.matches
			}
		}))
	)

	window.screens[screen] = mm
})

import { Iodine } from '@kingshott/iodine';

import Alpine from 'alpinejs'
import Clipboard from "@ryangjchandler/alpine-clipboard"

Alpine.plugin(Clipboard)

window.Alpine = Alpine

import './store'

import { xAsyncForm } from './xAsyncForm'
window.xAsyncForm = xAsyncForm

import { favorites } from './favorites'
window.favorites = favorites

import noUiSlider from 'nouislider'
window.rangeSlider = noUiSlider

Alpine.start()

