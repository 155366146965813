module.exports = {
	xAsyncForm: () => {
		return {
			inputElements: [],
			submitting: false,
			init() {
				//Store an array of all the input elements with 'data-rules' attributes
				Iodine.addRule('fullname', (value) => value.match("(.+ .*)|(.* .+)"))
				Iodine.setErrorMessage("fullname", 'Udfyld venligst dit fulde navn')
				Iodine.setErrorMessage("required", 'Feltet skal udfyldes')
				Iodine.setErrorMessage("email", 'Værdien skal skal være en rigtig email adresse')
				Iodine.setErrorMessage("numeric", 'Feltet må kun indeholde tal')
				this.inputElements = [...this.$el.querySelectorAll("input[data-rules]")];
				this.initDomData();
				this.updateErrorMessages();
			},
			initDomData: function () {
				//Create an object attached to the component state for each input element to store its state
				this.inputElements.map((ele) => {
					this[ele.name] = {
						serverErrors: JSON.parse(ele.dataset.serverErrors),
						blurred: false
					};
				});
			},
			updateErrorMessages: function () {
				//map throught the input elements and set the 'errorMessage'
				this.inputElements.map((ele) => {
					this[ele.name].errorMessage = this.getErrorMessage(ele);
				});
			},
			getErrorMessage: function (ele) {
				//Return any server errors if they're present
				if (this[ele.name].serverErrors.length > 0) {
					return input.serverErrors[0];
				}
				//Check using iodine and return the error message only if the element has not been blurred
				const error = Iodine.is(ele.value, JSON.parse(ele.dataset.rules));

				if (error !== true && this[ele.name].blurred && ele.offsetParent != null) {
					return Iodine.getErrorMessage(error);
				}
				//return empty string if there are no errors
				return "";
			},
			findParcelshops: function (address, zip) {
				if (!_self) {
					var _self = this
				}
				fetch('/umbraco/api/gls/findnearestparcelshops', {
					method: 'POST',
					body: { "Address": address, "ZipCode": zip },
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(function (response) {
					if (!response.ok) {
						throw Error(response.json())
					}
					return response.json();
				}).then(function (data) {
					_self.parcelShops = data
					if (data[0].ParcelShopId) {
						_self.setParcelshop(data[0].ParcelShopId, data[0].CompanyName)
						_self.selectedParcelshop = data[0].ParcelShopId
					}
				}).catch(function (json) {
					return console.log(json)
				})
			},
			getParcelshops: function () {
				var _self = this
				let address = {}
				address = {
					ZipCode: document.getElementById("parcelshop_zip") ? document.getElementById("parcelshop_zip").value : Alpine.store('checkout').data.BasketViewModel.ShippingAddressModel.SelectedAddress.PostalCode,
					Address: document.getElementById("parcelshop_address") ? document.getElementById("parcelshop_address").value : Alpine.store('checkout').data.BasketViewModel.ShippingAddressModel.SelectedAddress.AddressLine
				}
				fetch('/umbraco/api/gls/findnearestparcelshops', {
					method: 'POST',
					body: JSON.stringify(address),
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(function (response) {
					if (!response.ok) {
						throw Error(response.json())
					}
					return response.json();
				}).then(function (data) {
					_self.parcelShops = data
					if (data[0].ParcelShopId) {
						_self.setParcelshop(data[0].ParcelShopId, data[0].CompanyName)
						_self.selectedParcelshop = data[0].ParcelShopId
					}
				}).catch(function (json) {
					return console.log(json)
				})
			},
			setParcelshop: function (id, name, _self) {
				if (!_self) {
					var _self = this
				}
				let parcelshop = {}
				parcelshop = {
					ParcelShopId: id,
					ParcelShopName: name
				}
				fetch('/umbraco/api/checkout/provideparcelshopid', {
					method: 'POST',
					body: JSON.stringify(parcelshop),
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(function (response) {
					if (!response.ok) {
						throw Error(response.json())
					}
					return response.json();
				}).catch(function (json) {
					return console.log(json)
				})
			},
			submit: function (event) {
				this.inputElements = [...this.$el.querySelectorAll("input[data-rules]")];
				if (this.$el.dataset.type === 'shippingLoggedIn') {
					this.inputElements = [...document.querySelectorAll("#loggedInShippingAddress input[data-rules]")]
					document.getElementById("loggedInShippingAddressSubmit").click()
				}

				this.initDomData();
				this.updateErrorMessages();

				const invalidElements = this.inputElements.filter((input) => {
					if (input.offsetParent != null) {
						return Iodine.is(input.value, JSON.parse(input.dataset.rules)) !== true;
					}
				});
				if (invalidElements.length > 0) {
					event.preventDefault();
					document.getElementById(invalidElements[0].id).scrollIntoView();
					//We set all the inputs as blurred if the form has been submitted
					this.inputElements.map((input) => {
						this[input.name].blurred = true;
					});
					if (event.target.dataset.type === 'billing') {
						var selected = Alpine.store('checkout').data.BasketViewModel.CustomerType
						if (selected != null) {
							var privat = document.getElementById('Privat')
							privat.click()
							setTimeout(function () {
								document.getElementById(selected).click()
							}, 10);
						}
					}

					//And update the error messages.
					this.updateErrorMessages();
				}
				else {
					event.preventDefault();
					if (this.$el.dataset.action) {
						var _self = this
						this.submitting = true
						let customForm = {}

						if (this.$el.dataset.type === 'billing') {
							var formData = new FormData(event.target)
							customForm = {
								CurrentStep: formData.get("CurrentStep"),
								UseInvoiceAddressAsShippingAddress: formData.get("UseInvoiceAddressAsShippingAddress"),
								CustomerType: formData.get("CustomerType"),
								BillingAddressFormModel: Object.fromEntries(formData)
							}
							customForm = JSON.stringify(customForm)
						}
						if (this.$el.dataset.type === 'shippingLoggedIn') {
							customForm = JSON.stringify(Object.fromEntries(new FormData(document.getElementById("loggedInShippingAddress"))))
						}
						if (this.$el.dataset.type === 'shipping') {
							var deliveryAddress = document.getElementById("deliveryAddress") != null ? new FormData(document.getElementById("deliveryAddress")) : null
							var formData = new FormData(event.target)
							customForm = {
								CurrentStep: parseInt(formData.get("CurrentStep")) + 1,
								Address: deliveryAddress != null ? Object.fromEntries(deliveryAddress) : deliveryAddress
							}
							customForm = JSON.stringify(customForm)
						}
						if (this.$el.dataset.parcelshop) {
							_self.getParcelshops(_self)
						}

						fetch(this.$el.dataset.action, {
							method: 'POST',
							body: customForm.length ? customForm : JSON.stringify(Object.fromEntries(new FormData(event.target.closest("form")))),
							headers: {
								'Content-Type': 'application/json'
							}
						}).then(function (response) {
							if (!response.ok) {
								_self.submitting = false
								throw Error(response.json())
							}
							return response.json();
						}).then(function (data) {
							if (_self.$el.dataset.type === 'login') {
								if (data.ResetLoginRedirect != null && data.Url && data.ResetLoginRedirect && data.Url != "") {
									window.location.replace(data.Url)
								}
								else {
									if (data.IsLoggedIn) {
										_self.showWelcomeMessage = true
										_self.loggedIn = true
									}
									else {
										_self.loginFailed = true
									}
									_self.submitting = false
									Alpine.store('checkout').data = data
									_self.section = data.CurrentStep

									if (_self.$el.dataset.type === 'createaddress') {
										_self.open = false
										_self.ShippingAddressId = data.BasketViewModel.ShippingAddressModel.SelectedAddress.AddressId
									}
								}
							}
							else {
								if (data.IsLoggedIn) {
									_self.showWelcomeMessage = true
									_self.loggedIn = true
								}
								else {
									_self.loginFailed = true
								}
								_self.submitting = false
								Alpine.store('checkout').data = data
								_self.section = data.CurrentStep

								if (_self.$el.dataset.type === 'createaddress') {
									_self.open = false
									_self.ShippingAddressId = data.BasketViewModel.ShippingAddressModel.SelectedAddress.AddressId
								}
							}

						}).catch(function (json) {
							return console.log(json)
						})

					}
				}
			},
			change: function (event) {
				//Ignore all events that aren't coming from the inputs we're watching
				if (!this[event.target.name]) {
					return false;
				}
				if (event.type === "input") {
					this[event.target.name].serverErrors = [];
				}
				if (event.type === "focusout") {
					this[event.target.name].blurred = true;
				}
				//Whether blurred or on input, we update the error messages
				this.updateErrorMessages();
			}
		};
	},
}
